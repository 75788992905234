import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
//todo: larger fonts in numbers
const ProductsPage = () => {
  return (
    <Layout pageTitle="INCUS Products">
      <h1>INCUS Products</h1>
      <h4>
      Once launched, INCUS will produce a variety of different products available to the scientific community to advance our understanding of clouds and storms.       </h4>
      <p>
      Each product derived from the INCUS constellation is categorized into levels based on the amount of processing done, or as an auxiliary product for those variables derived solely from external sources. 
      </p>

      <h2>Level 1 Products</h2>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>
                        Product Name
                    </th>
                    <th>
                        Description
                    </th>
                </tr>
            </thead>
          <tbody>
            <tr>
              <td>1B-PWR</td>
              <td>Received power from each DAR I, calibrated and geolocated [dB]</td>
            </tr>
            <tr>
              <td>1C-Ze</td>
              <td>Equivalent radar reflectivity factor (Ze) [dBZ] and surface normalized radar cross section [dB]</td>
            </tr>
            <tr>
              <td>1C-Zcorrected</td>
              <td>Mispointing-corrected reflectivities, and advection-corrected reflectivities</td>
            </tr>
            <tr>
              <td>1B-Tb</td>
              <td>Calibrated DMR brightness temperature (Tb) [K]</td>
            </tr>

          </tbody>
        </Table>
        <h2>Level 2 Products</h2>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>
                        Product Name
                    </th>
                    <th>
                        Description
                    </th>
                </tr>
            </thead>
          <tbody>
            <tr>
              <td>2B-mass</td>
              <td>Vertical profile of condensate for each radar profile [kg m-3]</td>
            </tr>
            <tr>
              <td>2B-mass-rate</td>
              <td>Profile of temporal change in condensate for each radar pair [kg m-3 s-1]</td>
            </tr>
            <tr>
              <td>2B-Fluxes</td>
              <td>Profile of vertical mass flux of air and condensed-water estimated for every detected updraft [kg m-2 s-1]</td>
            </tr>
            <tr>
              <td>2B-DT</td>
              <td>2 min evolution of local advection, condensate and vertical fluxes in each cloud column</td>
            </tr>
            <tr>
              <td>2A-HD</td>
              <td>IWP [kg m-2] derived from the radiometer brightness temperature 1B-Tb</td>
            </tr>

          </tbody>
        </Table>
        <h2>Level 3 and 4 Products</h2>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>
                        Product Name
                    </th>
                    <th>
                        Description
                    </th>
                </tr>
            </thead>
          <tbody>
            <tr>
              <td>3A-LIFE</td>
              <td>Storm-centric LEVEL 2 and AUX data reported at half-hourly temporal resolution, labeled by storm</td>
            </tr>
            <tr>
              <td>3B-CONV</td>
              <td>Storm-wide statistics of vertical flux and environmental data, labelled by storm</td>
            </tr>
            <tr>
              <td>4-CONV</td>
              <td>Convection nowcasting system machine learning from 3B-CONV</td>
            </tr>
          </tbody>
        </Table>
        <h2>Auxiliary Products</h2>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>
                        Product Name
                    </th>
                    <th>
                        Description
                    </th>
                </tr>
            </thead>
          <tbody>
            <tr>
              <td>AUX-geoIR</td>
              <td>Lifecycle from geo-IR, convection type from cold Tbs, anvil size from IR Tb threshold</td>
            </tr>
            <tr>
              <td>AUX-reanal</td>
              <td>Large-scale environmental variables include CAPE, RH, T and shear</td>
            </tr>
            <tr>
              <td>AUX-Rsurf</td>
              <td>Surface precipitation from Integrated Multi-satellite Retrievals and GPM (IMERG)</td>
            </tr>
            <tr>
              <td>AUX-Light</td>
              <td>Lightning locations and flash rates from ground-based networks and spaceborne sensors</td>
            </tr>
            <tr>
              <td>AUX-GPM-Ka</td>
              <td>Coincident (GPM combined retrievals of IWC) and (DPR Ze)</td>
            </tr>

          </tbody>
        </Table>


    </Layout>

  )
}

export default ProductsPage

